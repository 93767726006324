<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="3" lg="3" class="mx-0 px-0 py-1 py-md-0">
                        <AlternativeAutocompleteOne
                            :label="'Empresa'"
                            :items="serverItems.companies"
                            :valueInput="selectedCompany"
                            :loading="loadingCompanies"
                            @valueChange="(v) => {selectedCompany = v; index()}"
                        ></AlternativeAutocompleteOne>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-permission="'business_assets.create'" cols="12" md="3" lg="2" class="mx-0 px-0 py-1 py-md-0">
                        <primaryButton :props="createBtnData"></primaryButton>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mx-0 px-0"> 
						<datatable :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="4">
						<secondaryButton
						:props="cancelModal"
						></secondaryButton>
					</v-col>
					<v-col cols="4">
						<primaryButton
						:props="acceptModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>


        <modal :props="modalReturnAssetProps" @modalResponse="method_handler">
			<!-- Buttons -->
			<template slot="cardActions">
				<v-row justify="end">
					<v-col cols="4">
						<secondaryButton
						:props="cancelReturnAssetModal"
						></secondaryButton>
					</v-col>
					<v-col cols="4">
						<primaryButton
						:props="acceptReturnAssetModal"
						></primaryButton>
					</v-col>
				</v-row>
			</template> 
		</modal>
		
	</div>
</template>
<script>
import Utils from "../../helpers/Utils";
import PageStateManager from '../../helpers/PageStateManager';
export default {
    data(){
		return {
            selectedCompany: PageStateManager.getState(this.$router.history.current.path, 'selectedCompany')??'ALL',
            loadingCompanies: false,
			selectedID: '',
			actions: [
				{
					name: 'Detalles',
					icon: {
						color: 'secondary',
						icon: 'mdi-information-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '',
                    permissions: 'business_assets.show'
				},
				{
					name: 'Editar',
					icon: {
						color: 'secondary',
						icon: 'mdi-pencil-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'business_assets.update'
				},
                {
					name: 'Asignar',
					icon: {
						color: 'secondary',
						icon: 'mdi-account-outline'
					},
					disabled: false,
					type: 'redirect',   //  method, external, redirect
					action: '/obras/1/edicion',
                    permissions: 'business_assets.create'
				},
                {
					name: 'Desasignar',
					icon: {
						color: 'secondary',
						icon: 'mdi-sync'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'returnAssetItemModal',
                    permissions: 'business_assets.update'
				},
				{
					name: 'Eliminar',
					icon: {
						color: 'secondary',
						icon: 'mdi-delete-outline'
					},
					disabled: false,
					type: 'method',   //  method, external, redirect
					action: 'deleteItemModal',
                    permissions: 'business_assets.delete'
				},

			],
			tableProps: {
				headers: [
					{
						text: 'Folio',
						align: 'left',
						value: 'id',
						class: 'table-b-border'
					},
                    {
						text: 'Descripción',
						align: 'left',
						value: 'description',
						class: 'table-b-border'
					},
                    {
						text: 'Marca',
						align: 'left',
						value: 'brand',
						class: 'table-b-border'
					},
                    {
						text: 'Empresa',
						align: 'left',
						value: 'business',
						class: 'table-b-border'
					},
                    {
                        text: 'Responsable',
                        align: 'left',
                        value: 'assigned',
                        class: 'table-b-border'
                    },
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'table-b-border'
					}
				],
				items: [],
				search: '',
				loading: false
			},
			
            createBtnData: {
                text: "Nuevo activo",
                icon: "mdi-plus",
				to: "InventoryCreate",
				block: true,
                click: ()=>{}
            },

			modalProps: {
				visible: false,
				width: '500',
				title: 'Eliminar',
				text: '¿Estás seguro de eliminar?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: '',
                click: ()=>{this.modalProps.visible = false}
            },
			acceptModal: {
                text: "SI, ELIMINAR",
                icon: "",
				to: "",
				block: false,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.delete()}
            },



            modalReturnAssetProps: {
				visible: false,
				width: '500',
				title: 'Remover responsable',
				text: '¿Estás seguro de remover al responsable del activo?',
				loading: false,
				bottomLineColor: 'error',
				
			},
			
			cancelReturnAssetModal: {
                text: "No, regresar",
                icon: "",
				to: "",
				block: false,
				color: '',
                click: ()=>{this.modalReturnAssetProps.visible = false}
            },
			acceptReturnAssetModal: {
                text: "SI, remover",
                icon: "",
				to: "",
				block: false,
				color: 'error',
				textColor:'white',
				loading:false,
                click: ()=>{this.returnAsset()}
            },

            serverItems:{
                companies:[]
            }
		}
	},

    watch: {
       
        [`selectedCompany`]: function () {
            console.log(this.selectedCompany)
            PageStateManager.setState(this.$router.history.current.path, 'selectedCompany', this.selectedCompany)
        },
    },

	mounted(){
		// this.index()
        this.getBusiness();
	},

    methods: {
        method_handler(object){
			this[object.method_name](object.parameters)
		},

        getBusiness()
        {
            this.serverItems.companies = [];
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
				.then((resp) =>{
                    
                    //index filter
					// this.serverItems.companies = resp.data.map((comp) => {
					// 	return {value: comp.id, text: `${comp.name}`}
					// });
                    this.serverItems.companies.push({value: "ALL", text: "Todas"});
                    this.serverItems.companies.push({value: "NONE", text: "Sin asignar"});
                    resp.data.forEach(comp => {
                        this.serverItems.companies.push({value: comp.id, text: `${comp.name}`});
                    });

                    

					if(this.serverItems.companies.length >= 1){
						if(this.selectedCompany == "")
                            this.selectedCompany = this.serverItems.companies[0].value;
						this.index();
                        
					}

                    
					
				})
				.catch((error) =>{
					console.log(error, "error getBusinessSelect");
				})
				.finally(() => {
                    this.loadingCompanies = false;
				})
			;
        },
		index() {
			this.tableProps.loading = true;
            this.tableProps.items = [];

            if(this.selectedCompany == null || this.selectedCompany == "")
                return;

			//call api
			this.$api.inventory.index(this.selectedCompany)
				.then((resp) =>{
					resp.data.forEach((x) => {
						x['actions'] = JSON.parse(JSON.stringify(this.actions))
						x.actions[0].action = { name: 'InventoryDetails', params: { id: x.id } };
						x.actions[1].action = { name: 'InventoryEdit', params: { id: x.id } };
						x.actions[2].action = { name: 'InventoryAssign', params: { id: x.id } }
						

						x['parameters'] = { id: x.id, name: x.description};
						x['business'] = Utils.getObjectValue(x,'employee.current_contract.branch_office.company.name')??'Sin asignar';
						x['assigned'] = Utils.getObjectValue(x,'employee.name')??'Sin asignar';
						// return x
                        
                        this.tableProps.items.push(x)
					});
				})
				.catch((error) =>{
					console.log(error, "error desde areas");
				})
				.finally(() => {
					this.tableProps.loading = false;
				})
			;
			//console.log(test);
			
			
			
		},

		deleteItemModal(parameters){
			this.selectedID = parameters.id;
			this.modalProps = {
				...this.modalProps,
				text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
			this.modalProps.visible = true;
			
		},
		delete(){
			this.acceptModal.loading = true;
			this.$api.inventory.delete(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se eliminó el activo`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error al eliminar. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalProps.visible = false;
				this.acceptModal.loading = false;
			})
		},


        returnAssetItemModal(parameters){
            console.log("ok modal mo")
			this.selectedID = parameters.id;
			this.modalReturnAssetProps = {
				...this.modalReturnAssetProps,
				//text: `¿Estás seguro de eliminar "${parameters.name}"?`,
				
			}
			this.modalReturnAssetProps.visible = true;
			
		},
		returnAsset(){
			this.acceptModal.loading = true;
			this.$api.inventory.returnAsset(this.selectedID)
			// eslint-disable-next-line
			.then((response) => {
				
				this.$store.dispatch('snackbarSuccess', `Se removió la asignación`);
				this.index()
				// eslint-disable-next-line
			}).catch(error => {
				

				this.$store.dispatch('snackbarError', `Error. Intenta de nuevo`);
			})
			.finally(() => {
				this.modalReturnAssetProps.visible = false;
				this.acceptModal.loading = false;
			})
		},


        



    }
}
</script>